<template>
  <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">

    <vx-card title="แก้ไข Rate ราคา">
      <vs-table :data="infoRank">
        <template slot="thead">
          <vs-th>
            ลำดับ
          </vs-th>
          <vs-th>
            ชื่อ
          </vs-th>
          <vs-th>
            เรท
          </vs-th>
          <vs-th>
            สถานะ
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].id">
              {{ data[indextr].id }}
            </vs-td>

            <vs-td :data="data[indextr].coin">
              {{ data[indextr].coin }}
            </vs-td>

            <vs-td :data="data[indextr].thb_rate">
              {{ currency(data[indextr].thb_rate) }}
            </vs-td>

            <vs-td :data="data[indextr].thb_rate">
              <p v-if="data[indextr].status == true" class="text-success">เปิด</p>
              <p v-else class="text-danger">ปิด</p>
            </vs-td>


            <vs-td :data="data[indextr].id">
              <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" type="border"
                size="small" icon-pack="feather" icon="icon-edit" class="mr-2"
                @click="edit(indextr)">แก้ไข</vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <vs-popup classContent="popup-example" :title="infopopup.coin" :active.sync="showpopup">
      <div class="vx-row ">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
          <vs-input class="w-full" label-placeholder="rate" v-model="infopopup.rate" type="number" name="Newturn" />
        </div>
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
          <p class="mb-3 ml-1  w-full" style="font-size: 9px;">สถานะ</p>
          <vs-switch :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true
            " v-model="infopopup.status">
            <span slot="on">เปิด</span>
            <span slot="off">ปิด</span>
          </vs-switch>
        </div>
      </div>
      <br>
      <vs-input class="w-full" label-placeholder="OTP" v-model="infopopup.otp"  name="Newturn" />
      <small class="text-danger" >กรุณากรอก OTP ทุกครั้งเพื่อยืนยันการแก้ไข</small>
      <br>
      <br>
      <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" size="small"
        icon-pack="feather" icon="icon-edit" class="mr-2" @click="confirm_edit()">ยืนยัน</vs-button>
    </vs-popup>
  </div>
</template>

<script>
import axios from '../../axios'

export default {

  data () {
    return {
      infoRank: '',
      showpopup: false,
      infopopup: {
        coin: '',
        rate: '',
        status: '',
        otp: ''
      },
      error: ''
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  mounted () {
    this.callinfo()
  },
  methods: {
    callinfo () {
      axios
        .get('/crypto/info')
        .then(response => (this.infoRank = response.data))
    },
    currency (amount) {
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        return `${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }

    },
    edit (index) {
      const i = index
      this.infopopup.coin = this.infoRank[i].coin
      this.infopopup.rate = this.infoRank[i].thb_rate
      this.infopopup.status = this.infoRank[i].status
      this.showpopup = true
    },
    async confirm_edit () {
      await axios.post('/crypto/editinfo', {
        coin: this.infopopup.coin,
        rate: this.infopopup.rate,
        statusCoin: this.infopopup.status,
        token : this.infopopup.otp
      })
        .then(response => (this.error = response.data))
      if (this.error.status === true) {

        this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          text: this.error.info,
          title: 'ทำรายการสำเร็จ'
        })
        this.callinfo()
        this.showpopup = false
        this.infopopup.otp = ''
      } else {
        this.callinfo()
        this.showpopup = false
        this.infopopup.otp = ''
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          text: this.error.info,
          title: 'ทำรายการผิดพลาด'
        })

      }
    }
  }
}

</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
